.trademark-registraion-container {
    padding: 20px;
    .row{
        padding-bottom: 15px;
    }
    .description {
        font-size: smaller;
        color: #606266;
        padding-right: 20px;
    }

    .content-title {
        margin-bottom: 5px;
    }


    
}
@primary-color: #57c2e7;