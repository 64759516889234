.filter-container {
    border-width : 3px;
    border-color : black;
    margin       : 4px;
    padding      : 3px;

    .filter-container-header {
        padding: 5px;
        height : 30px;
        .title-name{
            // width: 50%;
            display : inline-block;
        }
        // line-height: 30px;
        .check-all-container {
            display : inline-block;
            height: 20px;
            position: absolute;
            right   : 10px;
        }
    }

    .devider {
        margin-top   : 10px;
        margin-bottom: 5px;
    }

    .filter-container-body {
        width     : 100%;
        padding   : 5px;
        max-height: 100%;
        overflow  : auto;
    }


    .check-group {
        width     : 100%;
    }

}
@primary-color: #57c2e7;