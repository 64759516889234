@bodyWidth   : 480px;
@loginPadding: 10px;
@inputWidth  : 240px;
@errorWidth  : 300px;
@bodyLeft    : (@bodyWidth/2) - (@inputWidth/2) - @loginPadding;

.loginContainer {
    height          : 100%;
    width           : 100%;
    background-color: #57c2e7;
    position        : relative;

    .lgoinBody {
        width           : @bodyWidth;
        position        : absolute;
        top             : 50%;
        left            : 50%;
        transform       : translate(-50%, -50%);
        background-color: white;
        border-radius   : 10px;
        box-shadow      : 4px 6px 10px rgba(0, 0, 0, 0.16);
        padding         : @loginPadding;

        .header {
            margin        : auto;
            padding-top   : 32px;
            padding-bottom: 32px;

            .logo {
                display: block;
                margin : auto;
            }
        }

        .errorContainer {
            margin: auto;
            width : @errorWidth;
        }

        .body {
            margin: auto auto auto @bodyLeft;

            // width: @inputWidth;
            .item {
                margin: auto;
                height: 70px;

                .inputTitle {
                    margin-bottom: 5px;
                }

                .codeItem {
                    display: inline;

                }

                .input {
                    display: inline;
                    width  : @inputWidth;
                }

                .linkButton {
                    color: #57c2e7;
                }

                .passContainer {
                    width: @inputWidth;
                }
            }
        }

        .commit {
            margin-top: 20px;

            .submitButton {
                margin          : auto;
                width           : 50%;
                display         : block;
                background-color: #57c2e7;
                border-radius   : 5px;
                border-width    : 0px;
                color           : white;
            }

            .newButton {
                margin-top: 5px;
                color     : #57c2e7;
            }

            .jumpButton {
                margin-top: 20px;
                color     : #57c2e7;
            }
        }
    }

    .footer {
        width     : 100%;
        position  : absolute;
        bottom    : 39px;
        text-align: center;
        color     : white;
    }
}
@primary-color: #57c2e7;