.ef-content-container{
    overflow: 'initial';
    margin: 10px;
    // margin-left: 256px;
    background-color: white;
    top: 60px;
    border-radius: 10px;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.16);
    
}
@primary-color: #57c2e7;