.dashboard-container {
    padding-top   : 10px;
    padding-bottom: 10px;
    padding-left  : 20px;
    padding-right : 20px;

   
    .search-container{
        padding: 20px 10px 20px 10px;
        .search-item-title{
            margin-right: 10px;
        }
        .search-item-container{
            display: inline-block;
            margin-right: 20px;
        }
    }
    .row {
        margin-bottom: 20px;
    }

    
    .table-column-header {
        background-color: cyan;
    }

    .basic-info-container {
        display    : flex;
        align-items: center;
        height     : 108px;

        .icon {
            display    : inline-block;
            width      : 15%;
            height     : 100%;
            margin-left: 15%;
            display    : flex;
            align-items: center;
        }

        .content {
            display: inline-block;
            width  : 90%;

            .title {
                color: #606266;
            }

            .result {
                font-weight: bolder;
            }

            .subtitle {
                margin-right: 5px;
            }
        }

        p {
            margin-bottom: 3px;
        }
    }

    .map-container {
        height: 400px;
    }

    .chart-element {
        width : 98%;
        height: 400px;
    }

    .chart-element-type2 {
        width : 98%;
        height: 240px;
    }
}
@primary-color: #57c2e7;