.ef-menu-container {
    // position: fixed;
    // width: auto;
    // bottom: 0px;
    padding-bottom   : 100px;
    background-color: #000B16;
}

.ef-side-container {
    background-color: #000B16;
    box-shadow         : 4px 6px 10px rgba(0, 0, 0, 0.16);
    // position        : fixed;
    z-index            : 999;
    overflow-y         : hidden;
}

.logo-wrapper {
    height       : 60px;
    width        : inherit;
    left         : 0;
    top          : 0;
    text-align   : center;
    margin-top   : auto;
    margin-bottom: auto;
}

.logo-img {
    max-height    : 80%;
    max-width     : 80%;
    vertical-align: middle;
    transform     : translateY(50%);
}

.logo-img-small {
    max-height    : 50%;
    max-width     : 50%;
    vertical-align: middle;
    transform     : translateY(50%);
}

.ef-submenu {
    // line-height: 40px;
    position        : absolute;
    background-color: #57c2e7;
    top             : 0px;
    left            : 0px;
    right           : 0px;
    bottom          : 0px;
    z-index         : 0;
    padding-left    : 24px;
    padding-right   : 16px;
    margin-top      : 4;
}

.ef-notice-badge {
    // 
    float: right;
    top  : 10px;
}
@primary-color: #57c2e7;