.notice-table-container {
    padding: 20px 10px 10px 10px;
}

.notice-search-container {
    padding: 20px 10px 10px 10px;
}

.notice-unviewd-row {
    background-color: #F8DFDF;
}

.notice-tableview-header {
    text-align: right;
    float: right;
    padding-right: 20px;
    padding-bottom: 4px;
}
@primary-color: #57c2e7;