.container {
    width     : 100%;
    min-height: 100vh;


    .content-container-normal {
        margin-left: 256px;
    }

    .content-container-small {
        margin-left: 80px;
    }
}
@primary-color: #57c2e7;