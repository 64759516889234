.ws-list-container {
    padding: 0px 10px 10px 10px;

    .client-selector-container {
        padding   : 0px 0px 0px 15px;
        margin-top: 0px;

        .client-title {
            display     : inline;
            margin-right: 15px;
        }
    }

    .collapse-container{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .cell-wapper{
        width: 150px;
    }

}
@primary-color: #57c2e7;