.breadContainer {
    display    : inline;
    line-height: 50px;

    .bread {
        padding: auto;
        display: inline-block;
    }

    .actionContainer {
        position: absolute;
        right   : 40px;
    }

    .divider {
        margin: 0px;
    }

    .breadHeader {
        display         : inline-block;
        background-color: #57c2e7;
        color           : red;
        margin-right    : 10px;
        margin-left     : 0px;
        width           : 3px;
        height          : 20px;
        vertical-align  : middle;
    }

    .breadcrumb-button {
        min-width: 100px;
    }
}
@primary-color: #57c2e7;