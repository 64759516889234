.ef-simple-container{
    margin: 12px;
    clear: both;
    .container-title{
        font-weight: bolder;
    }
    .divider{
        margin-top: 5px;
    }
}
@primary-color: #57c2e7;