body {
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: "Meiryo UI","Meiryo","ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","メイリオ","Meiryo","Osaka","ＭＳ Ｐゴシック","MS PGothic","sans-serif";
  }
  
  html {
    height: 100%;
    box-sizing: border-box;
  }
  #root {
    height: 100%;
  }
@primary-color: #57c2e7;