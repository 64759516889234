.user-detail-container {
    padding: 20px;

    p {
        display: inline;
    }

    .item-wrapper {
        margin-bottom: 20px;

    }


    .item-label-container {
        display: inline;
        width  : 120px;
        float  : left;
    }

    .item-content-container {
        display    : inline;
        font-weight: bolder;
    }

    .acition-container {
        padding: 10px;
    }

    .update-button {
        width: 100px;
    }

    .input-text {
        display            : inline;
        // background-color: #f1f1f1;
        width              : 60%;
    }

    .remarks-text {
        width              : 60%;
        height             : 100px;
        // background-color: #f1f1f1;
    }

    .company-select {
        min-width: 100px;
    }


}
@primary-color: #57c2e7;