.ec-list-container {
    padding: 0px 10px 10px 10px;

    
    .client-selector-container {
        padding   : 0px 0px 0px 15px;
        margin-top: 10px;

        .client-title {
            display     : inline;
            margin-right: 15px;
        }
    }

    .cell-wapper{
        width: 150px;
    }

}

.collapse-container{
    margin-top: 15px;
    margin-bottom: 15px;
}

.ec-list-view-listContainer {
    height  : 400px;
    overflow: auto;
    width   : 100%;

    .check-all-contaner {
        width : 100%;
        height: 40px;
    }
}

.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position  : absolute;
    width     : 2px;
    height    : 100%;
    bottom    : 0;
    right     : 0px;
    cursor    : col-resize;
    background: red;
    z-index   : 1;
}

.ec-list-error {
    background-color: #F8DFDF;
}
@primary-color: #57c2e7;