.ec-detail-container {
    padding: 20px;

    p {
        display: inline;
    }

    .scrape-info-row {
        margin-top: 10px;
    }

    .tabs-container {
        margin-left : 10px;
        margin-right: 10px;
    }

    .section-container {
        margin-bottom: 40px;
    }

    .acition-container {
        padding: 10px;
    }

    .update-button {
        width      : 100px;
        margin-left: 12px;
    }
    
    .select-box{
        width: 60%;
    }

    .input-text {
        // display: inline;
        // background-color: #f1f1f1;
        width: 60%;
    }

    .input-textarea {
        // display: inline;
        // background-color: #f1f1f1;
        width: 80%;
    }

    .process-container {
        margin-top: 30px;
    }

    .process-container-hide {
        display: none;
        margin-top: 30px;
    }


    .search-site {
        margin-left: 10px;
    }

    .description-text {
        height: 100px;
    }

    .iframe-detail {
        float: right
    }

    .pagination-container {
        display   : inline;
        width     : 60%;
        float     : right;
        text-align: right;
    }

}
@primary-color: #57c2e7;