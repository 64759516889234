.trademark-container {
    padding: 20px;

    p {
        display: inline;
    }

    .item-wrapper {
        margin-bottom: 20px;
    }

    .item-label-container {
        display: inline;
        width  : 200px;
        float  : left;
    }

    .item-long-label-container {
        display: inline;
        width  : 400px;
        float  : left;
        margin-bottom: 5px;
    }


    .item-required-label-container {
        display: inline;
        width  : 200px;
        float  : left;
    }

    .item-required-label-container::after{
        content:"*";
        color: red;
    }

    .item-content-container {
        display    : inline;
        font-weight: bolder;
    }

    .avatar-uploader {
        width: 300px;
        height: 104px;      
    }

    .acition-container {
        padding: 10px;
    }

    .update-button {
        width      : 100px;
        margin-left: 12px;
    }

    .input-text {
        // display: inline;
        // background-color: #f1f1f1;
        width: 240px;
    }

    .select-box {
        // display: inline;
        // background-color: #f1f1f1;
        width: 240px;
    }

    .search-site {
        margin-left: 10px;
    }

    .description-text {
        height: 100px;
    }

}
@primary-color: #57c2e7;