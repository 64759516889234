.ef-table-header-container {
    font-size   : 20px;
    height      : 40px;
    width       : 100%;

    .ef-table-left-container{
        display: inline-block;
        text-align: left;
        .infringement-option{
            width: 200px;
        }
    } 
    .ef-table-total-container {
        display: inline-block;
    }

    .ef-table-action-container {
        text-align: right;
        display   : inline-block;
        color     : #57c2e7;
        width     : 50%;
        height    : 40px;
        right     : 40px;
        position  : absolute;

        .header-button {
            width : 26px;
            height: 36px;
        }
        .total-item{
            margin-right: 30px;
            font-size: 15px;
            color: black;
        }
    }
}
@primary-color: #57c2e7;