.seller-list {
    width : 100%;
    height: 100%;

    .seller-item {
        width  : 90%;
        height : 100%;
        padding: 5px;

        .item-value {
            font-weight: bolder;
        }
    }
}

.seller-ribbon {
    height : 20px;
    width  : 30px;
    margin : 0px;
    padding: 0px;
}
@primary-color: #57c2e7;