.chart-container {
    border-radius: 5px;
    box-shadow   : 3px 4px 4px rgba(0, 0, 0, 0.16);
    padding      : 5px;
    margin       : 5px;

    .header {
        height          : 40px;
        line-height     : 40px;
        text-align      : left;
        margin          : auto;
        vertical-align  : middle;

        .header-hint {
            display         : inline-block;
            background-color: #57c2e7;
            color           : red;
            width           : 3px;
            height          : 20px;
            margin-right    : 6px;
            transform       : translateY(25%)
        }

        p {
            display: inline;
            color  : #606266;
            width  : 200px;
        }
    }

    .divider {
        margin: 0;
    }

    .chart-container-body {
        padding: 5px;
    }
}
@primary-color: #57c2e7;